import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { ModalType } from '@movecloser/front-core';
import { Alert, AlertTheme } from '@component/Alert';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { AccountForm } from '../../components/AccountForm';
import { AuthRepositoryType } from '../../contracts/repositories';
import { MODAL_TRANSITION_DURATION } from '@config/modal';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let PasswordResetView = class PasswordResetView extends Vue {
    constructor() {
        super(...arguments);
        this.alertTheme = AlertTheme.Warning;
        this.message = '';
        this.errors = '';
        this.hasErrors = false;
        /**
         * Object that collects User input.
         */
        this.formData = {
            email: '',
            password: '',
            passwordConfirmation: ''
        };
        /**
         * Determines whether the component is currently performing any async actions.
         */
        this.isActing = false;
    }
    onAlertClose() {
        this.hasErrors = false;
    }
    /**
     * Handles the `@cancel` event on the `<AccountForm>` component.
     */
    onCancel() {
        this.redirectToHomepage();
    }
    /**
     * Handles the `@submit` event on the `<AccountForm>` component.
     */
    onSubmit() {
        this.isActing = true;
        const { email, password, passwordConfirmation } = this.formData;
        const token = this.apiToken;
        this.authRepository.resetPassword({
            email,
            password,
            passwordConfirmation,
            token
        })
            .then(() => {
            this.redirectToHomepage();
            this.openSuccessModal();
        })
            .catch((e) => {
            this.message = e.message;
            this.errors = Object.values(e.payload).map(error => {
                return error;
            });
            this.hasErrors = true;
        })
            .finally(() => {
            this.isActing = false;
        });
    }
    /**
     * `api_token` param from the URL query.
     */
    get apiToken() {
        return this.$route.params.token;
    }
    /**
     * Opens the "error" modal.
     */
    openErrorModal() {
        this.modalConnector.open(EModal.Info, {
            body: this.$t('_.something-went-wrong'),
            title: this.$t('_.oops') + '!'
        });
    }
    /**
     * Opens the "success" modal.
     */
    openSuccessModal() {
        this.modalConnector.open(EModal.Info, {
            body: this.$t('modules.profile.views.password-reset.modal.success.body'),
            buttons: [{
                    label: this.$t('_.log-in'),
                    className: 'btn-primary',
                    handler: () => {
                        setTimeout(() => {
                            this.modalConnector.open(EModal.LogIn);
                        }, MODAL_TRANSITION_DURATION);
                    }
                }],
            title: this.$t('_.success') + '!'
        });
    }
    /**
     * Redirects the User to the homepage.
     */
    redirectToHomepage() {
        this.$router.push('/');
    }
};
__decorate([
    Inject(AuthRepositoryType)
], PasswordResetView.prototype, "authRepository", void 0);
__decorate([
    Inject(ModalType)
], PasswordResetView.prototype, "modalConnector", void 0);
PasswordResetView = __decorate([
    Component({
        name: 'PasswordResetView',
        components: {
            AccountForm,
            Alert,
            Screen
        },
        asyncData(context) {
            const { redirect, params } = context;
            const apiToken = params.token;
            if (typeof apiToken !== 'string' || apiToken.length === 0) {
                redirect({ path: '/' });
            }
            return {};
        }
    })
], PasswordResetView);
export { PasswordResetView };
export default PasswordResetView;
